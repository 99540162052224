import { reactive, ref } from 'vue'
import { isValidPhoneNumber, validatePhoneNumberLength, isPossiblePhoneNumber } from 'libphonenumber-js'
import parseMax from 'libphonenumber-js/max'
import { ERROR_MESSAGE_PHONE } from '~/constant/common'

const useRules = () => {
  const isEmpty = (fieldName, fieldValue) => {
    // return !fieldValue ? `This ${fieldName} is required.` : ''
    return !fieldValue ? 'This is a required field.' : ''
  }

  const isEmptyEmailFooter = (fieldName, fieldValue) => {
    return !fieldValue ? 'Enter your email address.' : ''
  }

  const isEmail = (fieldName, fieldValue, fieldType) => {
    // let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/
    let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(fieldType === 'auth' || fieldType === 'formSubmit'){
      return !re.test(fieldValue.toLowerCase())
      // ? 'Please enter a valid email address (Ex: johndoe@domain.com).'
      ? 'This email address does not seem to exist.'
      : ''
    } else{
      return !re.test(fieldValue.toLowerCase())
      ? `This ${fieldName} address does not seem to exist.`
      : ''
    }
  }

  const isNum = (fieldName, fieldValue) => {
    let isNum = /^\d+$/.test(fieldValue);
    return !isNum ? "The " + fieldName + " field only have numbers." : ""
  }

  const isPhone = (fieldValue, fieldCode) => {
    let isValidPhone = isValidPhoneNumber(fieldValue, fieldCode)
    let isValidPhoneLength = validatePhoneNumberLength(fieldValue, fieldCode)
    let isPossiblePhone = isPossiblePhoneNumber(fieldValue, fieldCode)

    if(isValidPhoneLength === 'TOO_SHORT'){
      // return "This phone number is too short."
      return ERROR_MESSAGE_PHONE[2]
    } else if(isValidPhoneLength === 'TOO_LONG') {
      // return "This phone number is too long."
      return ERROR_MESSAGE_PHONE[1]
    } else if(! (isValidPhone && isPossiblePhone)) {
      //return "This phone number does not seem to exist."
      return ERROR_MESSAGE_PHONE[0]
    } else {
      if (! parseMax(fieldValue, fieldCode).isValid()) {
        //return "This phone number does not seem to exist."
        return ERROR_MESSAGE_PHONE[0]
      }
    }
  }

  const minLength = (fieldName, fieldValue, min) => {
    return fieldValue.length < min ? `This ${fieldName} must be at least ${min} digits long.` : ""
  }

  const isPassword = (fieldName, fieldValue, min) =>{
    const isContainsLowercase = /^(?=.*[a-z]).*$/
    const isContainsNumber = /^(?=.*[0-9]).*$/

    if(fieldValue.length < min){
      return `Passwords must be at least ${min} characters in length.`
    } else if (!isContainsLowercase.test(fieldValue) || !isContainsNumber.test(fieldValue)) {
      return "Passwords must include both numeric and alphabetic characters."
    } else {
      return ''
    }
  }

  const isCcv = (fieldName, fieldValue) => {
    let isCcv = /^[0-9]{3}$/.test(fieldValue)
    return !isCcv ? "The " + fieldName + " must be at least 3 digits long." : ""
  }

  const isValidCreditCardNumber = (fieldName, fieldValue) =>{
    let regVisa = /^4[0-9]{12}(?:[0-9]{3})?$/
    let regMaster = /^5[1-5][0-9]{14}$/
    let regAmerica = /^3[47][0-9]{13}$/

    if (!regVisa.test(fieldValue) || !regMaster.test(fieldValue) || !regAmerica.test(fieldValue)) {
     return "Card number is not valid."
    } else {
      return
    }
  }

  // validate is a valid url domain website
  const isDomain = (fieldName, fieldValue) => {
    // eslint-disable-next-line
    let re = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/i
    return !re.test(fieldValue) ? "This website does not seem to exist." : null
  }

  return { isEmpty,isEmptyEmailFooter, isEmail, isNum, isPhone, minLength, isPassword, isCcv, isValidCreditCardNumber, isDomain }
}

const errors = reactive({});

const isAnyErrors = ref(false)

const useFormValidation = () => {
  const { isEmpty, isEmptyEmailFooter, isEmail, isNum, isPhone, isPassword, isDomain } = useRules();

  const validateEmailFieldFooter = (fieldName, fieldValue, fieldType) => {
    errors[fieldName] = !fieldValue ? isEmptyEmailFooter(fieldName, fieldValue) : isEmail('email', fieldValue, fieldType)
  }

  const validateEmailField = (fieldName, fieldValue, fieldType, required = true) => {
    if (required) {
      errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isEmail(fieldName, fieldValue, fieldType)
    } else {
      errors[fieldName] = fieldValue ? isEmail(fieldName, fieldValue, fieldType) : ""
    }
  }

  const validateNameField = (fieldName, fieldValue) => {
    errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : ""
  }

  const validateDomainField = (fieldName, fieldValue, required = true) => {
    if (required) {
      errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isDomain(fieldName, fieldValue)
    } else {
      errors[fieldName] = fieldValue ? isDomain(fieldName, fieldValue) : ""
    }
  }

  const validatePasswordField = (fieldName, fieldValue) => {
    errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isPassword(fieldName, fieldValue, 7)
  }

  const validateNumberField = (fieldName, fieldValue) => {
    errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isNum(fieldName, fieldValue)
  }

  const validatePhoneNumberField = (fieldName, fieldValue, fieldCode, required = true) => {
    if (required) {
      errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isPhone(fieldValue, fieldCode)
    } else {
      errors[fieldName] = fieldValue ? isPhone(fieldValue, fieldCode) : ""
    }
  }

  const validateCCVField = (fieldName, fieldValue) =>{
    errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : ""
  }

  const resetErrors = () => {
    const arrayKeys = Object.keys(errors)
    if (arrayKeys.length) {
      arrayKeys.forEach(item => errors[item] = '')
    }
  }

  const checkAnyErrors = () => {
    const checkValue = Object.values(errors).filter(item => item).length
    isAnyErrors.value = checkValue
  }

  return {
    errors,
    validateEmailField,
    validatePasswordField,
    validateNumberField,
    validateNameField,
    validatePhoneNumberField,
    validateCCVField,
    validateEmailFieldFooter,
    validateDomainField,
    resetErrors,
    isAnyErrors,
    checkAnyErrors
  }
}

export default useFormValidation
